body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main);
}

::placeholder {
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main: #234759;
  --main-dark: #182f3c;
  --main-darker: #0c181e;
  --secondary: #3b7695;
  --secondary-light: #b0cedd;
  --secondary-light2: #9cbfd1;
  --secondary-lighter: #ecf8ff;
  --light-blue: #c3e0ec;
  --light-blue-lighter: #cbe5f0;
  --grey-darker: #3f4a59;
  --grey-dark: #858585;
  --grey: #d7e1e6;
  --grey-light: #f0f6f9;
  --secondary-table: #f5fbff;
  --yellow-table: #fff2d9;
  --background: #f5f6fa;
  --white: #ffffff;
  --green: #afe052;
  --green-light: #b8e269;
  --green-dark: #abdb4c;
  --warning: #ffcc60;
  --warning-light: #fdde9a;
  --warning-lighter: #fae5b7;
  --warning-lighter2: #fff9ec;
  --warning-dark: rgb(252, 190, 58);
  --error: #ff6060;
  --critical: #eb2020;
  --high: #f7a059;
  --medium: #ffcc60;
  --note: #276fbf;
  --dark-layer: rgba(32, 70, 141, 0.5);
  --accent-02: #ff015b;
  --accent-02-light: #ffcfe0;
  --import: #65d8c8;
  --import-light: #def7f4;
  --export: #9776e5;
  --export-light: #f2eefc;
  --sales: #9776e5;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-track {
  background: var(--grey-light);
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-light);
  border-radius: 25px;
}
